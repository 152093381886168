var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "status-section" },
    [
      _vm.statusData
        ? [
            _c("div", { staticClass: "status-wrapper" }, [
              _c("div", { staticClass: "status-item" }, [
                _c("div", { staticClass: "title" }, [_vm._v("Version")]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.statusData.version)),
                ]),
              ]),
              _c("div", { staticClass: "status-item" }, [
                _c("div", { staticClass: "title" }, [_vm._v("Agent Count")]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.statusData.agent_count)),
                ]),
              ]),
              _c("div", { staticClass: "status-item" }, [
                _c("div", { staticClass: "title" }, [_vm._v("Client Count")]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.statusData.client_count)),
                ]),
              ]),
              _c("div", { staticClass: "status-item" }, [
                _c("div", { staticClass: "title" }, [_vm._v("Site Count")]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.statusData.site_count)),
                ]),
              ]),
              _c("div", { staticClass: "status-item" }, [
                _c("div", { staticClass: "title" }, [_vm._v("Disk Usage")]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.statusData.disk_usage_percent) + "%"),
                ]),
              ]),
              _c("div", { staticClass: "status-item" }, [
                _c("div", { staticClass: "title" }, [_vm._v("Memory Usage")]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.statusData.mem_usage_percent) + "%"),
                ]),
              ]),
              _c("div", { staticClass: "status-item" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v("Days Until Cert Expires"),
                ]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.statusData.days_until_cert_expires)),
                ]),
              ]),
              _c("div", { staticClass: "status-item" }, [
                _c("div", { staticClass: "title" }, [_vm._v("Cert Expired")]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.statusData.cert_expired ? "Yes" : "No")),
                ]),
              ]),
              _c("div", { staticClass: "status-item services" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v("Services Running"),
                ]),
                _c(
                  "div",
                  { staticClass: "services-list" },
                  _vm._l(
                    _vm.statusData.services_running,
                    function (status, service) {
                      return _c(
                        "div",
                        { key: service, staticClass: "service" },
                        [
                          _c("span", { staticClass: "service-name" }, [
                            _vm._v(_vm._s(service)),
                          ]),
                          _c(
                            "span",
                            {
                              class: [
                                "service-status",
                                status ? "running" : "stopped",
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(status ? "Running" : "Stopped") +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ]),
            ]),
          ]
        : _vm._e(),
      _vm.errorMessage
        ? [
            _c("div", { staticClass: "error-message" }, [
              _c("span", { staticClass: "text" }, [
                _vm._v(_vm._s(_vm.errorMessage)),
              ]),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }